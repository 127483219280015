<template>
  <div class="website">
    <header>
      <top-title
      title="網站製作"
      subTitle="">
      </top-title>
    </header>
    <div class="container">
      <page-main-body
        title="一個結合社群機制與網站製作的開始"
        subTitle="Let us present our fantastic digital marketing tools"
        paragraph1="網站如同一本書，網頁即是書頁，網站也可區分長期品牌形象或是短期活動宣傳，相同類型下再加入了近幾年流行的社群機制分享，采億/采盟團隊多年來網頁設計、程式編寫經驗，再搭配趣味話題，為您量身打造屬於您的專屬視覺。">
      </page-main-body>
      <div class="demos">
        <div id="step">
          <h1>我們將網站製作分為...</h1>
        </div>
        <div class="row">
          <div class="column">
            <div class="example" @mouseover="hover">
              <div class="promo"></div>
              <span>
                <h2>產品宣傳</h2>
                <router-link :to="{ name: 'CaseStudies', params: { caseType: 'productPromote' }}">觀看相關案例</router-link>
              </span>
            </div>
            <div class="example" @mouseover="hover">
              <div class="interact"></div>
              <span>
                <h2>互動機制</h2>
                <router-link :to="{ name: 'CaseStudies', params: { caseType: 'interactive' }}">觀看相關案例</router-link>
              </span>
            </div>
          </div>
          <div class="example" @mouseover="hover">
            <div class="branding"></div>
            <span>
              <h2>品牌形象</h2>
              <router-link :to="{ name: 'CaseStudies', params: { caseType: 'brandImage' }}">觀看相關案例</router-link>
            </span>
          </div>
        </div>
      </div>
      <h2>製作流程</h2>
      <div class="steps">
        <div class="colIcon">
          <div class="rowIcon" v-for="(step, index) in steps" :key="index" >
            <img class="icons" :src="`${baseUrl}static/steps/step${index+1}.svg`"/>
            <div class="step">
              <span><font>{{index+1}}.</font>{{step.topic}}</span><br/>
              <p>{{step.content}}</p>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <footer-view style="margin-top:15px;" />
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "website",
  components: {
    topTitle,
    pageMainBody,
    footerView
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      baseUrl: process.env.BASE_URL,
      st: 0,
      steps: [
        {
          topic: "初步洽談，了解需求",
          content:
            "與客戶討論網站藍圖，從架站目的、版面風格、功能需求去了解客戶的要求，並給予專業領域的建議。"
        },
        {
          topic: "擬定架構，進行報價",
          content:
            "專案設計人員依客戶給予的需求提出完整報價單，以便客戶確認製作內容、和製作時間等相關資料。"
        },
        {
          topic: "簽訂合約，支付訂金",
          content:
            "簽訂合約書，以保障雙方的權利義務，同時客戶開始準備所有製作所有素材。"
        },
        {
          topic: "網站風格建制",
          content:
            "設計師依客戶給予的資料提交首頁主視覺版面，接下來與客戶確認主視覺色調與風格後，開始製作所有內頁。"
        },
        {
          topic: "版面確認",
          content:
            "設計師製作除了首頁之外的所有頁面及後台頁面規劃，仍然會與客戶確認所有版面，再進行下一步驟。"
        },
        {
          topic: "程式撰寫",
          content:
            "提交網頁程式製作，客戶開始驗收測試功能是否正常，錯誤部分需提供修改清單，讓程式進行除錯。"
        },
        {
          topic: "正式上線",
          content:
            "內容完成後，客戶可選購主機及網址後，網站就可以上線，此時會加入 Google 分析代碼進行追蹤，並協助登入路口網站(Yahoo、Google、Bing) 。"
        }
      ]
    };
  },
  methods: {
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop;
      var vm = this;
      var hook = window.innerHeight;
      $(".step").each(function(idx) {
        if (vm.st > $(this).offset().top - hook * 0.88) {
          $(this).addClass("popout");
        }
      });
      $(".icons").each(function(idx) {
        if (vm.st > $(this).offset().top - hook * 0.88 - 20) {
          $(this).addClass("iconPopout");
        }
      });
      $(".example").each(function() {
        if (
          vm.st > $(this).offset().top - hook * 0.88 &&
          vm.st < $(this).offset().top - window.innerHeight * 0.12
        ) {
          $(this).addClass("hover");
        } else {
          $(this).removeClass("hover");
        }
      });
    },
    hover() {}
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../assets/image/service/website/topBanner.jpg");
}
.website {
  background-color: #fff;
  .container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include font(medium);
      margin: 4% 0;
    }
    .demos {
      display: flex;
      flex-wrap: wrap;
      width: 80vw;
      height: 26vw;
      margin-top: 4%;
      @media #{$tablet} {
        height: 800px;
        width: 90vw;
      }
      @media #{$phone} {
        flex-direction: column;
        width: 100vw;
        height: unset;
      }
      #step {
        flex: 7;
        @media #{$tablet} {
          flex: unset;
          width: 100%;
        }
        @media #{$phone} {
          flex: 5;
        }
        background-color: #73cccf;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          @include font(medium);
        }
        h2 {
          @include font(small);
          margin: 2% 0;
          font-weight: 300;
        }
      }
      .row {
        flex: 7;
        display: flex;
        @media #{$tablet} {
          flex: unset;
          width: 100%;
          height: 400px;
        }
        @media #{$phone} {
          flex-direction: column;
          height: 150vw;
        }
        .column {
          flex: 4;
          @media #{$phone} {
            flex: 2;
            height: 100vw;
          }
          display: flex;
          flex-direction: column;
          .example {
            flex: 1;
            .promo {
              background-image: url("../../assets/image/service/website/promo.jpg");
            }
            .interact {
              background-image: url("../../assets/image/service/website/interact.jpg");
            }
          }
        }
        .example {
          flex: 3;
          @media #{$phone} {
            flex: 1;
            height: 50vw;
          }
          .branding {
            background-image: url("../../assets/image/service/website/branding.jpg");
            @media #{$phone} {
              background-image: url("../../assets/image/service/website/brandingM.jpg");
            }
          }
        }
      }
    }
    .steps {
      $iconH: 160px;
      width: 80vw;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      @media #{$tablet} {
        flex-direction: row;
        align-items: flex-start;
        justify-items: center;
        width: 90vw;
      }
      @media #{$slim} {
        width: 98vw;
      }
      .colIcon {
        display: flex;
        flex-direction: column;
        @media #{$tablet} {
          align-items: flex-start;
          flex: 1;
        }
        & :nth-child(odd).rowIcon {
          align-self: flex-start;
        }
        & :nth-child(even).rowIcon {
          align-self: flex-end;
        }
        .icons {
          height: $iconH;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          @media #{$tablet} {
            height: ($iconH * 0.7);
            left: 0;
            transform: translateX(0);
          }
        }
        .step {
          font {
            @include font(large);
          }
          text-align: left;
          @media #{$tablet} {
            margin-left: calc(#{$iconH} * 0.7 + 1%);
          }
        }
        .rowIcon {
          height: $iconH;
          width: calc(50% - #{$iconH} * 0.5 );
          display: flex;
          align-items: center;
          margin: 2% 0;
          @media #{$tablet} {
            width: 100%;
          }
        }
      }
    }
  }
}
.popout {
  opacity: 1 !important;
  animation: popout 0.7s ease;
}

.iconPopout {
  opacity: 1 !important;
  animation: iconPopout 0.7s ease;
  @media #{$tablet} {
    animation: popout 0.7s ease;
  }
}

@keyframes popout {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  60% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes iconPopout {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }
  60% {
    opacity: 1;
    transform: translateX(-50%) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.example {
  cursor: pointer;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    z-index: 3;
    display: none;
    transition: 0.5s;
    h2 {
      @include font(small);
      margin-top: 0 !important;
      margin-bottom: 15px !important;
      font-weight: 300;
    }
    a {
      color: #fff;
      text-decoration: none;
      background-color: #f12379;
      padding: 8px 14px;
      @include font(small);
    }
  }
  div {
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: 0.5s ease;
    background-size: 100% 100%;
    background-position: 50% 50%;
    position: absolute;
  }
  &:hover {
    span {
      display: block;
    }
    div {
      background-size: 110% 110%;
      filter: brightness(0.5);
    }
  }
  @media #{$tablet} {
    &.hover {
      span {
        display: block;
      }
      div {
        background-size: 110% 110%;
        filter: brightness(0.5);
      }
    }
  }
}
</style>
